export default {
    cake: {
      137: '0xb8E67956C68c0160A3c13466D1806dDd96B8a8C0',
      80001: '0x98b799349c321E9D5E918607c871ED479aCfb22F',
    },
    masterChef: {
      137: '0x988c33D345b0565a9F5B1D0f5074cb78071e038e',
      80001: '0x06e9033E9C24e794Be8F6C7881c9519053533DCE',
    },
    wbnb: {
      137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      80001: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    },
    lottery: {
      137: '',
      80001: '',
    },
    lotteryNFT: {
      137: '',
      80001: '',
    },
    mulltiCall: { 
      137: '0x6c716af369343bB723415d95e33c406e6A7D61Fb',
      80001: '0x0EAD1160bd2Ca5A653e11FAe3D2b39E4948BDA4d',
    },
    busd: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',     // usdt
      80001: '0xc199cfdc2f09ed2227df61d4b8294b2bb3b24609',
    },
    usdc:{
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',     // usdc
      80001: '0xc199cfdc2f09ed2227df61d4b8294b2bb3b24609',
    }
  }
  
  
  