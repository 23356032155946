import addresses from 'constants/contracts'
import { ChainId } from '@oboswap/matic-sdk'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[ChainId.MAINNET]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[ChainId.MAINNET]
}
export const getMulticallAddress = () => {
  return addresses.mulltiCall[ChainId.MAINNET]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[ChainId.MAINNET]
}
export const getLotteryAddress = () => {
  return addresses.lottery[ChainId.MAINNET]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[ChainId.MAINNET]
}
