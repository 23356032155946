import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'OBO-USDC LP',
    lpAddresses: {
      80001: '',
      137: '0xa3fb5dcaf06c3a6e3ff577a0dbea9cd4941df493',
    },
    tokenSymbol: 'obo',
    tokenAddresses: {
      80001: '',
      137: '0xb8e67956c68c0160a3c13466d1806ddd96b8a8c0',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  }
]

export default farms
