import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://oboswap.com/farms/coming_soon.html'
  },
  {
    label: 'Swap',
    icon: 'TradeIcon',
    href: '/swap'
  },
  {
    label: 'Pool',
    icon: 'GroupsIcon',
    href: '/pool'
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://oboswap.com/farms/coming_soon.html'
  },
 
  {
    label: 'Info',
    icon: 'InfoIcon',
    href: 'https://analytics-poly.oboswap.com/',
    // items: [
    //   {
    //     label: 'Analytics',
    //     href: 'https://analytics-poly.oboswap.com/',
    //   },
    
    // ]
  },
  {
    label: 'Docs',
    icon: 'MoreIcon',
    href: "https://obo-swap.gitbook.io/oboswap/",
    // items: [
    //   // {
    //   //   label: 'Github',
    //   //   href: '',
    //   // },
    //   {
    //     label: "Docs",
    //     href: "https://obo-swap.gitbook.io/oboswap/",
    //   },
     
    // ],
  },

]

export default config
